<template>
  <v-row class="dashboard">
    <v-col cols="12" class="pa-0">
      <div class="title">
        <div class="white--text text-uppercase text-sm-h5 text-h6 font-weight-bold">Dashboard</div>
      </div>
      <v-card
        v-if="user.status == 'blocked'"
        outlined
        tile
        max-width="1070"
        height="70vh"
        min-height="250"
        class="mx-lg-auto mx-4 mx-sm-15 pa-3 d-flex align-center justify-center flex-column"
      >
        <div class="primary--text font-weight-bold text-h6">This account is blocked</div>
        <v-card flat tile max-width="400" class="mt-3 mb-10 text-center">
          No actions are allowed for the current account. Please switch to the other account or sign-in to the other profile
        </v-card>
        <v-btn @click="logout" height="48" width="164" depressed color="primary">
          <v-icon class="pr-3">mdi-download mdi-rotate-270</v-icon> Sing Out
        </v-btn>
      </v-card>
      <v-card
        v-else-if="load"
        tile
        elevation="5"
        max-width="1070"
        height="70vh"
        min-height="250"
        class="mx-lg-auto mx-4 mx-sm-15 pa-3 d-flex align-center justify-center flex-column"
      >
        <v-img max-height="80" contain src="@/assets/icon/loader.gif"></v-img>
        <div class="text-body-2 opasity--text">Loading...</div>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
export default {
  metaInfo() {
    return {
      title: this.$title(`${this.$route.name}`),
    };
  },
  data() {
    return {
      load: true,
    };
  },
  mounted() {
    setTimeout(() => {
      this.load = false;
    }, 3000);
  },
  methods: {
    async logout() {
      await this.$store.dispatch('logout');
    },
  },
  computed: {
    user() {
      return this.$store.getters.account;
    },
  },
};
</script>
